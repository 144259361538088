<template>
  <div style="min-width: 1366px;white-space: nowrap;">
    <div class="title_block">
      <p class="text-center">
        <span class="m-l-100 text-24">
          {{situationName}} 广告资源态势
        </span>
        <Icon class="m-l-10 m-r-5" type="ios-clock-outline" size="16" color="#5cadff" />
        <span class="text-14">{{currentTime}}</span>
      </p>
    </div>

    <slot name="left_box">
      <div class="left-block" :style="{'background': waterImageUrl}">
        <div class="box_content">
          <slot name="left_box_content"></slot>
        </div>
      </div>
    </slot>

    <slot name="right_box">
      <div class="right-block" style="width: 360px;" :style="{'background': waterImageUrl}" >
        <div class="box_content">
          <slot name="right_box_content"></slot>
        </div>
      </div>
    </slot>

    <slot name="bottom_box">
      <div class="bottom-block" style="left:462px;right:362px" :style="{'height': bottomHeight, 'background': waterImageUrl}">
        <div class="box_content">
          <slot name="bottom_box_content"></slot>
        </div>
      </div>
    </slot>

    <slot name="center_box">
      <div class="center-block" :style="{'bottom': bottomHeight}">
        <div class="box_content">
          <slot name="center_box_content"></slot>
        </div>
      </div>
    </slot>
  </div>
</template>

<script>
import { getStorage } from '@/utils/storage'
import { ParseDateTime } from '@/utils/dateFormat'
import { getUserWaterImage } from '@/api/dw/image'

export default {
  data () {
    return {
      situationName: '',
      currentTime: '',
      waterImageUrl: ''
    }
  },
  created () {
    this.loadTime()
    this.loadWaterImage()

    if (this.$store.getters.token.publisherSetting.statisticsTitle) {
      this.situationName = this.$store.getters.token.publisherSetting.statisticsTitle
    } else {
      this.situationName = this.$store.getters.token.userInfo.publishers
        .find(x => x.id === this.$store.getters.token.userInfo.publisherId).name
    }
  },
  methods: {
    loadTime () {
      this.currentTime = ParseDateTime(new Date())
      setTimeout(() => {
        this.loadTime()
      }, 1000)
    },
    loadWaterImage () { // 载入水印图片
      const param = {
        loginUserId: this.$store.getters.token.userInfo.userId,
        color: this.themeIsDark ? 'black' : 'white'
      }

      getUserWaterImage(param).then(res => {
        this.waterImageUrl = 'url(' + res[0].value + ') repeat'
      })
    }
  },
  computed: {
    bottomHeight: {
      get () {
        return this.$store.state.sys.bottomHeight
      },
      set (val) {
        this.setBottomHeight(val)
      }
    },
    themeIsDark () {
      // 获取并设置当前页面的主题
      const theme = getStorage('oohforce-workbench-theme') || 'dark'
      return theme === 'dark'
    }
  }
}
</script>

<style scoped>
.box_content {
  height: 100%;
  padding: 8px 8px;
  overflow-y: scroll;
  scrollbar-width: none; /* FireFox */
  -ms-overflow-style: none; /*IE10+*/
}

.box_content::-webkit-scrollbar {
  display: none; /* Chrome Safari */
}
</style>
