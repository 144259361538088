import { GetCurrentMonth, GetPrevMonth } from '@/utils/dateFormat'
// import { GetCurrentMonth } from '@/utils/dateFormat'

const getDefaultState = () => {
  return {
    searchSchedule: {
      startDate: GetPrevMonth(new Date()),
      // startDate: '2021-06-01',
      endDate: GetCurrentMonth().endDate
    },
    compareSchedule: {
      startDate: '',
      endDate: ''
    },
    companyId: '0',
    assetId: '0',
    mapShowAssetIds: [], // 用于兼容北京地铁一条线路ID，包含多条线路的问题
    productIds: [],
    gbType: 'industry',
    gbId: null,
    beignUpdateMap: new Date(),
    beignUpdateBottomAndRight: new Date(),
    orderType: '1',
    rightTableId: null,
    rightTableRowBean: null,
    // 小微
    dateRange: {
      startDate: '',
      endDate: ''
    },
    selectedIndustryId: null,
    selectedUserId: null,
    mapStations: []
  }
}

const state = getDefaultState()
const situationWeb = {
  state,
  mutations: {
    reset_state (state) {
      Object.assign(state, getDefaultState())
    },
    set_situation_searchSchedule (state, data) {
      state.searchSchedule = data
    },
    set_situation_compareSchedule (state, data) {
      state.compareSchedule = data
    },
    set_situation_companyId (state, data) {
      state.companyId = data
    },
    set_situation_assetId (state, data) {
      state.assetId = data
    },
    set_situation_mapShowAssetIds (state, data) {
      state.mapShowAssetIds = data
    },
    set_situation_productIds (state, data) {
      state.productIds = data
    },
    set_situation_gbType (state, data) {
      state.gbType = data
    },
    set_situation_gbId (state, data) {
      state.gbId = data
    },
    set_situation_beignUpdateMap (state, data) {
      state.beignUpdateMap = data
    },
    set_situation_beignUpdateBottomAndRight (state, data) {
      state.beignUpdateBottomAndRight = data
    },
    set_situation_orderType (state, data) {
      state.orderType = data
    },
    set_situation_rightTableId (state, data) {
      state.rightTableId = data
    },
    set_situation_rightTableRowBean (state, data) {
      state.rightTableRowBean = data
    },
    set_date_range (state, data) {
      state.dateRange = data
    },
    set_selected_industry_id (state, data) {
      state.selectedIndustryId = data
    },
    set_selected_user_id (state, data) {
      state.selectedUserId = data
    },
    set_situation_map_stations (state, data) {
      state.mapStations = data
    }
  },
  actions: {

  }
}

export default situationWeb
